.privacy-policy-explicit-opt-in {
  $modal-width: 460px;
  font-family: "Source Sans Pro", sans-serif;
    
  .backdrop {
    background-color: #303133;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
  }
    
  .content-wrapper {
    background-color: #fff;
    border-radius: 6px;
    width: $modal-width;
    z-index: 101;
    left: 50%;
    top: 50%;
    margin-left: calc(-#{$modal-width} / 2);
    position: fixed;
    padding: 24px 32px 32px 32px;
    transform: translateY(-50%);

    h2 {
      color: #16171B;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    button.close {
      background: transparent;
      border: none;
      margin: unset;
      padding: unset;
      transition: none;

      color: #001A27;        ;
      cursor: pointer;
      font-size: 30px;
      font-weight: normal;
      line-height: 1;
      width: 16px;

      position: absolute;
      right: 18px;
      top: 10px;
    }

    .modal-content {
      display: flex;
      flex-direction: column;

      p {
        color: #16171B;
        font-weight: 400;
        font-size: 14px;
      }
    }

    .modal-actions {
      display: flex;
      flex-direction: column;

      button {
        border-radius: 4px;
      }

      a.view-updated-privacy-policy {
        background-color: transparent;
        border-radius: 4px;
        border: 1px solid #367CAC;
        color: #367CAC;

        font-weight: 700;
        font-size: 16px;
        padding: 10px;
        text-align: center;
      }

      button.updated-privacy-policy-assertive {
        font-weight: 700;
        font-size: 16px;
        padding: 10px;
      }
    }

    .modal-action-error {
      color: #AA1527;
      font-size: 14px;
      font-weight: 400;
      margin-top: 16px;

      &.hidden {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 928px) {
  .privacy-policy-explicit-opt-in {
    .content-wrapper {
      border-radius: 0;
      height: 100%;
      left: 0;
      margin: 0;
      top: 0;
      transform: none;
      width: 100%;

      h2 {
        color: #000000;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
